<template>
  <div class="orders">
    <div class="page-header">
      <h1>{{ $t('Order & Document Management') }} > {{ $t('Orders') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Orders') }}</h2>
        <router-link :to="{ name: 'CreateOrder' }">
          <el-button class="action-button" type="primary">{{ $t('Create Order') }}</el-button>
        </router-link>
      </div>
      <div class="content-tabs">
        <el-tabs v-model="activeName" @tab-click="onTabChange" type="card">
          <el-tab-pane :label="$t('All')" name="All">{{ $t('All') }}</el-tab-pane>
          <el-tab-pane :label="$t('Unpaid')" name="Unpaid">{{ $t('Unpaid') }}</el-tab-pane>
          <el-tab-pane :label="$t('Unfulfilled')" name="Unfulfilled">{{ $t('Unfulfilled') }}</el-tab-pane>
          <el-tab-pane :label="$t('Completed')" name="Completed">{{ $t('Completed') }}</el-tab-pane>
          <el-tab-pane :label="$t('Cancelled')" name="Cancelled">{{ $t('Cancelled') }}</el-tab-pane>
        </el-tabs>
      </div>
      <ItemFilter :placeholder="$t('Search by...')" default="orderNum" @search="searchItems">
        <el-option :label="$t('Order No. Search')" value="orderNum"></el-option>
        <el-option :label="$t('Customer Name Search')" value="customerName"></el-option>
      </ItemFilter>
      <div class="order-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Order No.') }}</th>
              <th scope="col">{{ $t('Date') }}</th>
              <th scope="col">{{ $t('Order Status') }}</th>
              <th scope="col">{{ $t('Total') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders">
              <td>{{ order.reference_id }}</td>
              <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ $t(order.order_status) }}</td>
              <td>HK$ {{ loadOrderAmount(order) }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Order', params: {id: order.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';
import Customer from '@/lib/customer';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'Orders',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadOrders();
  },
  data(){
    return {
      activeName: 'All',
      orders: [],
      searchOrders: null, //Keep the tab result
      orignalOrders: [],
    }
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'orderNum':
        searchField['reference_id'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalOrders);
        this.searchOrders = newItems;
        this.orders = newItems;
        break;
      }
    },
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    loadOrderAmount(order){
      const relatedProducts = JSON.parse(order.related_product);
      const productInfos = order.product_infos;
      const totalAmount = this.loadItemAmount(productInfos, relatedProducts);
      return totalAmount;
    },
    loadItemAmount(productInfos, relatedProducts){
      let totalAmount = 0;
      for(let i = 0; i < relatedProducts.length; i++ ){
        const currentProduct = relatedProducts[i];
        const productInfo = productInfos.find(function(item){
          return currentProduct.id === item.id;
        });
        const productPrice = productInfo['product_regular_price'];
        const productAmount = productPrice * currentProduct.quantity;
        totalAmount += productAmount;
      }
      return totalAmount;
    },
    getOptionInfo(productInfo, variation, option){
      const variationInfo = JSON.parse(productInfo.product_variations).find(function(item){
        if(variation.variation_name_en === item.variation_name_en || variation.variation_name_zh === item.variation_name_zh){
          return true;
        }
      });
      const optionInfo = variationInfo.options.find(function(item){
        if(option === item.option_name_en || option === item.option_name_zh){
          return true;
        }
      });
      return optionInfo;
    },
    async loadOrders(){
      try{
        const loginInfo = Common.getLoginInfo();
        let orders = await Order.loadAllOrders(this.apiUrl, loginInfo);
        orders = orders.filter(function(item) {
          return item.type !== 'POS';
        });
        this.orignalOrders = orders;
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
    async deleteOrder(orderId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Order.deleteOrder(this.apiUrl, orderId, loginInfo);
        const newOrders = this.orders.filter(function(item) {
          return item.id !== orderId;
        });
        this.orignalOrders = newOrders;
        this.orders = newOrders;
      }catch(err){
        console.log(err);
      }
    },
    async onTabChange(){
      const activeName = this.activeName;
      if(activeName !== 'All'){
        this.loadLimitOrders(activeName);
      }else{
        this.orders = this.orignalOrders;
      }
    },
    async loadLimitOrders(type){
      const searchFields = {
        'order_status': '',
      }
      if(type === 'Unpaid'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Unpaid';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Unpaid';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Unfulfilled'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Unfulfilled';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Unfulfilled';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Completed'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Completed';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Completed';
          });
          this.orders = newItems;
        }
      }
      if(type === 'Cancelled'){
        if(this.searchOrders){
          const newItems = this.searchOrders.filter(function(order){
            return order['order_status'] == 'Cancelled';
          });
          this.orders = newItems;
        }else{
          const newItems = this.orignalOrders.filter(function(order){
            return order['order_status'] == 'Cancelled';
          });
          this.orders = newItems;
        }
      }
      if(type === 'All'){
        if(this.searchOrders){
          this.orders = searchOrders;
        }else{
          this.orders = orignalOrders;
        }
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
